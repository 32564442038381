// Here you can add other styles
.react-datepicker-wrapper {
  width: 100%;
}

.css-13cymwt-control,
.css-1nmdiq5-menu {
  background-color: var(--cui-body-bg) !important;
  background-clip: padding-box !important;
  border: var(--cui-border-width) solid var(--cui-border-color) !important;
}

.css-d7l1ni-option,
.css-t3ipsp-control:hover {
  border-color: inherit !important;
  box-shadow: 0 0 0 0.25rem rgba(88, 86, 214, 0.25) !important;
}

.css-t3ipsp-control {
  background-color: inherit !important;
  box-shadow: none !important;
  border: #fff !important;
}

.css-1dimb5e-singleValue {
  color: #000000 !important;
}

.css-tr4s17-option {
  background-color: #282e3b !important;
}

.toast-box {
  position: fixed;
  z-index: 9999;
  top: 12px;
}

.class-cover {
  width: -webkit-fill-available;
}

.show-table {
  @media (min-width: 992px) {
    display: inline-table !important;
  }
}

// .btn-primary {
//   --cui-btn-color: #fff !important;
//   --cui-btn-bg: #5c5c7e !important;
//   --cui-btn-border-color: #6d6ca7 !important;
//   --cui-btn-hover-color: #fff !important;
//   --cui-btn-hover-bg: #373745 !important;
// }

.css-166bipr-Input input {
  color: rgb(255, 255, 255) !important;
}

.header.header-sticky {
  z-index: 999 !important;
}


.table-responsive {
  overflow-x: clip !important;
}